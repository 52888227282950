import React from "react"
import Layout from "../components/layout"
import SEO from "../components/SEO"
import ServicesPageContent from "../components/ServicesPageContent"

const props = {
  title: "Our Services",
  description:
    "We provide our services for free to all genders. We are here to help with:",
  services: [
    {
      title: "Emotional Support",
      html: "<p>Confidential listening, validation and encouragement.</p>",
      iconId: "heartCircle",
    },
    {
      title: "Information",
      html: "<p>Referrals and community resources.</p>",
      iconId: "info",
    },
    {
      title: "Sexual Assault Counselling",
      html:
        "<p>Through agency partnership with Nahnahdah-wee-ee-waywin Centre, Sexual Assault Counselling is available on a one-on-one basis as needed. This service is free to all victims of sexual assault. To find out more or to book an appointment, please contact our office at <a href='tel:1-807-737-1700'>807-737-1700</a>.</p>",
      iconId: "counsel",
    },
    {
      title: "Practical Assistance",
      html:
        "<p>Safety planning, accompaniment, transportation to a safe location, and other arrangements.</p>",
      iconId: "heartCircle",
    },
  ],
  referrals: {
    title:
      "Referrals from Police and emergency services may include, but not limited to the following: ",
    items: [
      "Serious Assaults",
      "Criminal Harassment",
      "Domestic Violence",
      "Personal Crisis",
      "Sexual Assault",
      "Homicide / Attempt to commit murder",
      "Human Trafficking",
    ],
  },
  program: {
    title: "Victim Quick Response Program+ (VQRP+)",
    html:
      "<p>The VQRP+ provides short-term financial assistance to direct victims, their immediate family members, and witnesses in the immediate aftermath of violent crimes in Ontario. The program is designed to help enhance safety and meet immediate practical needs arising from the commission of the crime.</p><p>VQRP+ is a program of last resort where no other financial sources have been obtained. Timelines and eligibility criteria apply.</p><p>For all program inquiries, please contact us or you may visit the <a href='http://www.attorneygeneral.jus.gov.on.ca/english/ovss/' target='_blank' rel='noreferrer'>Victim Quick Response Program page (in English)</a> on The Ministry of the Attorney General website.</p>",
  },
}

const Services = () => {
  return (
    <Layout>
      <SEO title="Services" />
      <ServicesPageContent {...props} />
    </Layout>
  )
}

export default Services
