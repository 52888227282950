import React from "react"
import classNames from "classnames"
import { BodySmall } from "../Typography"

import styles from "./index.module.scss"

type PropsType = {
  items: string[]
  twoColumns?: boolean
}

const List = ({ items, twoColumns }: PropsType) => {
  return (
    <ul
      className={classNames(styles.list, twoColumns && styles.listTwoColumns)}
    >
      {items.map((item, i) => (
        <li
          className={classNames(
            styles.listItem,
            twoColumns && styles.listItemTwoColumns
          )}
          key={i}
        >
          <BodySmall>{item}</BodySmall>
        </li>
      ))}
    </ul>
  )
}

export default List
