import React from "react"

import Wrapper from "../Wrapper"
import Row from "../Row"
import Column from "../Column"
import Icon from "../Icon"

import { BodyLarge, H2, H3 } from "../Typography"

import styles from "./index.module.scss"
import List from "../List"

type Props = {
  services: Array<{ title: string; html: string; iconId: string }>
  referrals: {
    title: string
    items: string[]
  }
  program: {
    title: string
    html: string
  }
  title: string
  description: string
}

const ServicesPageContent = ({
  title,
  description,
  services,
  referrals,
  program,
}: Props) => (
  <>
    <section className={styles.pageContent}>
      <Wrapper>
        <Row>
          <Column mobile={8} desktop={13}>
            <H2 className={styles.title} component="h1">
              {title}
            </H2>
            <BodyLarge>{description}</BodyLarge>
          </Column>
        </Row>
        <Row center className={styles.row}>
          <Column mobile={8} desktop={12}>
            <Row>
              {services.map((service, i) => (
                <Column
                  mobile={8}
                  desktop={8}
                  key={`service-${i}`}
                  className={styles.servicesItem}
                >
                  <Icon
                    svgId={service.iconId}
                    width="64"
                    height="64"
                    className={styles.servicesIcon}
                  />
                  <div className={styles.serviceItemText}>
                    <H3 component="h2">{service.title}</H3>
                    <div
                      dangerouslySetInnerHTML={{ __html: service.html }}
                      className={styles.body}
                    />
                  </div>
                </Column>
              ))}
            </Row>
          </Column>
        </Row>
        <Row center className={styles.row}>
          <Column mobile={8} desktop={8}>
            <BodyLarge fontWeight="bold" className={styles.referralsTitle}>
              {referrals.title}
            </BodyLarge>
            <List items={referrals.items} twoColumns />
          </Column>
        </Row>
      </Wrapper>
    </section>
    <section className={styles.programSection}>
      <Wrapper>
        <Row center>
          <Column mobile={8} desktop={12}>
            <H3 component="h2">{program.title}</H3>
            <div
              dangerouslySetInnerHTML={{ __html: program.html }}
              className={styles.body}
            />
          </Column>
        </Row>
      </Wrapper>
    </section>
  </>
)

export default ServicesPageContent
